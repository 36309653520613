module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    extend: {
      width: {
        38: '2.375rem',
        140: '8.75rem',
        160: '10rem',
        1200: '75rem',
      },
      height: {
        64: '4rem',
        210: '13.125rem',
        240: '15rem',
        270: '16.875rem',
        368: '23rem',
        446: '27.875rem',
        480: '30rem',
        620: '38.75rem',
        720: '45rem',
      },
      inset: {},
      zIndex: { 100: '100' },
      padding: {},
      margin: {},
      //   fontSize: {},
      colors: {
        grey: {
          50: '#fafafa',
          100: '#f5f5f5',
          200: '#eeeeee',
          300: '#e0e0e0',
          400: '#bdbdbd',
          500: '#9e9e9e',
          600: '#757575',
          700: '#616161',
          800: '#424242',
          900: '#212121',
        },
        blue: {
          600: '#002FA7',
        },
      },
      screens: {
        xs: '0px',
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1536px',
      },
      //   spacing: {
      //   },
    },
  },
  plugins: [],
};
